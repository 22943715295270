import styled from "styled-components"
import _Text from "src/componentsV2/ui/Text"

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    img {
      margin-top: 60px;
    }

    .col {
      padding: 0;
    }

    .title,
    .desc {
      padding-top: 20px;
      max-width: 100%;
    }
  }
`

export const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .title {
    margin-bottom: 0 !important;
  }

  @media (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    img {
      margin-top: 60px;
    }

    .col {
      padding: 0;
    }

    .title,
    .desc {
      padding-top: 20px;
      max-width: 100%;
    }
  }
`

export const Col = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    &.description {
      max-width: 450px;
    }
  }
`

export const Img = styled.img`
  text-align: center;
  max-width: 300px;
  width: 100%;
  height: auto;
`
